import React from 'react';
import Hero from '../Components/Hero/Hero';
import LocationInMap from '../Components/Map/LocationInMap';
import Contact from '../Components/Contact/Contact';
import Department from '../Components/Department/Department';
import Appointment from '../Components/Appointment/Appointment';
import About from '../Components/About/About';
import Iconbox from '../Components/Iconbox/Iconbox';
import PostWrapper from '../Components/Post/PostWrapper';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import BrandSlider from '../Components/Slider/BrandSlider';
import Newsletter from '../Components/Newsletter/Newsletter';
import Accordion from '../Components/Accordion/Accordion';
import PriceSlider from '../Components/Slider/PriceSlider';
import Funfact from '../Components/Funfact/Funfact';
import BeforeAfter from '../Components/BeforeAfter/BeforeAfter';
import MasonryGallery from '../Components/Gallery/Gallery';
import ContactInfo from '../Components/ContactInfo/ContactInfo';
import { useLocation } from 'react-router-dom';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },/*
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },*/
  ],
  title: ['Crutches', 'Laboratory', 'Cardiology', 'Dentist', 'Neurology'],
};

const iconboxData = [
  {
    fieldTitle: 'Yaptığımız İşlemler',
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Deri tümörleri tanı ve tedavisi',
    subTitle:
      'Deri tümörlerinin ayırıcı tanısı için dermatoskopik muayene, biyopsi  ve cerrahi eksizyon yapılmaktadır. Ayrıca hastalığın izlenmesi, kişisel ve ailesel risklerin saptanması ile koruyucu hekimlik danışmanlığı uygulanmaktadır.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Tırnak hastalıkları tanı tedavi ve cerrahisi',
    subTitle:
      'Tırnak hastalıklarının ayırıcı tanısı çok önemlidir, bu nedenle genel dermatolojik muayene mutlaka yapılır. Dermoskopi, mantar aranması ve bakteriyel kültürler, deri tümörleri için biyopsi yapılabilir. Tırnak batmalarında kimyasal ve cerrahi matriksektomi, yatak revizyonu, elektrokoterizasyon, kriyoterapi, tırnak tümörlerinde cerrahi eksizyon yapılabilir.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Kimyasal peeling',
    subTitle:
      'Ciltteki lekelerin, kırışıklıkların, akne ve yara izlerinin tedavisinde, cilt tonu eşitlenmesinde, güneş hasarı sonucu oluşan deri değişikliklerinde kimyasal Peeling tedavisi uygulanmaktadır.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Terleme tedavisi',
    subTitle:
      'Terleme bozuklukları bazen yaşam kalitesini çok bozabilir ve başka hastalıklarla ilişkili olabilir, Muayenehanemizde hastalar bu açılardan tam bir muayene ve tetkikleri sonunda gerekli ise ter testi yapılmaktadır. Botulinum toksin uygulaması , iyontoforez gibi tedavi seçeneklerimiz vardır.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Akne tedavisi',
    subTitle:
      'Muayenehanemizde her akne hastası kişiye özel değerlendirilmektedir.Genel sağlık durumu,beslenme kullandığı ilaçlar ve aknenin yaşam kalitesi üzerindeki etkisi gözönüne alınarak tedavi planları oluşturulmaktadır. Cilt temizliği ve cilt bakımı, kimyasal Peeling, reçeteli ilaçlar (topikal veya oral) , kistik lezyonlarda cerrahi tedaviler, akne izlerinde mikroiğneleme, subsizyon uygulanmaktadır.',
  },
];

const aboutData = {
  title:
    'Hakkımda',
  subTitle:
    'Ankara Atatürk Anadolu Lisesi’nde 1989 da mezun oldu.  Ankara Üniversitesi Tıp Fakültesi’ni 1995 yılında bitirdi. 1996-2000 yılları arasında Ege Üniversitesi Deri ve Zührevi Hastalıklar Anabilim Dalı’nda uzmanlık eğitimini tamamlayarak 2000 yılında uzman oldu. Yaptığı bilimsel ve akademik faaliyetlerle 2012 yılında Doçent ve 2019 yılında Profesör ünvanı aldı. 2001-2010 yılları arasında Ankara’da Özel Bayındır Hastanesinde dermatoloji uzmanı olarak özellikle dermatolojik cerrahi, lazer tedavileri ve kozmetik işlemlere odaklı olarak çalıştı. 2010 yılında KKTC Yakın Doğu Üniversitesi Tıp Fakültesi Deri ve Zührevi Hastalıklar Anabilim Dalı’nın kurdu. 2010-2015 yılları arasında Anabilim Dalı Başkanı olarak görev yaptı. 2016 yılında İstanbul Marmara Üniversitesi Tıp Fakültesi Deri ve Zührevi Hastalıklar Anabilim Dalı’na atanarak deri tümörleri ve onkolojisi, dermatolojik cerrahi ve kozmetik dermatoloji birimlerinin sorumluluğunu üstlendi. Lisans ve lisansüstü düzeyde dersler veren Dr. Kaptanoğlu’nun yurtiçi ve yurtdışında saygın bilimsel dergilerde yayınlanan çok sayıda makalesi, kitap bölümleri, deneysel araştırmaları ve editörlükleri vardır. Prof. Dr. Aslı F. Kaptanoğlu çalışmakta olduğu Marmara Üniversitesi Tıp Fakültesi’nden 2024 yılında emekli oldu. Ankara Atatürk Anadolu Lisesi Mezunları Vakfı, Türk Dermatoloji Derneği, Dermatoonkoloji Derneği, European Academy of Dermatology & Venereology ve European Nail Society üyesi olan Prof. Dr. Aslı F. Kaptanoğlu eğitim ve danışmanlık hizmetlerinin yansıra kendi özel muayenehanesini kurmuş olup hastalarına burada hizmet vermeye devam etmektedir.',

  avater: {
    img: 'images/avatar1.png',
    name: 'David Ambrose',
    designation: 'Founder & Director',
  },
  timeTable: [
    {
      day: 'Monday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Tuesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Wednesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Thursday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Friday',
      startTime: '9:00',
      endTime: '5:00',
    },
    {
      day: 'Saturday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Sunday',
      startTime: '9:00',
      endTime: '3:00',
    },
  ],
  contact: '+90 (501) 000 42 66',
};

const specialistData = [
  {
    img: 'images/member1.jpg',
    name: 'Dr. Philip Bailey',
    designation: 'Urology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member2.jpg',
    name: 'Dr. Vera Hasson',
    designation: 'Cardiology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member3.jpg',
    name: 'Dr. Matthew Hill',
    designation: 'Neurosurgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member4.jpg',
    name: 'Dr. Jeanette Hoff',
    designation: 'Surgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member1.jpg',
    name: 'Dr. Philip Bailey',
    designation: 'Urology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member2.jpg',
    name: 'Dr. Vera Hasson',
    designation: 'Cardiology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member3.jpg',
    name: 'Dr. Matthew Hill',
    designation: 'Neurosurgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member4.jpg',
    name: 'Dr. Jeanette Hoff',
    designation: 'Surgery',
    authorLink: '/doctor-profile',
  },
];

const beforeAfterData = {
  bgImg: '/images/before-after-bg.jpg',
  beforeImg: '/images/after.jpg',
  afterImg: 'images/before.jpg',
};

const testimonialData = [
  {
    img: 'images/avatar2.png',
    name: 'Ralph Jones',
    designation: 'Executive',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar3.png',
    name: 'Francis Jara',
    designation: 'Biographer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar4.png',
    name: 'David Baer',
    designation: 'UX Designer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar2.png',
    name: 'Ralph Jones',
    designation: 'Executive',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar3.png',
    name: 'Francis Jara',
    designation: 'Biographer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar4.png',
    name: 'David Baer',
    designation: 'UX Designer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
];

const priceData = [
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
];

const faqData = {
  title: 'Just Answer the Questions',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'What is Medi solution?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'How do I get a refill on my prescription?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'How competent our total treatment?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'If I get sick what should I do?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'What is emergency cary to your hospital?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ],
};

const newsletterData = {
  bgImg: 'images/news-letter-bg.png',
  contact: '(+01) - 234 567 890',
};

const postData = [
  {
    img: 'images/blog1.jpg',
    title: 'Working in emergency medicine',
    date: 'Aug 23, 2020',
    author: 'Albert Brian',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog2.jpg',
    title: 'Individual treatment & assistance',
    date: 'Aug 22, 2020',
    author: 'William Juarez',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
];

const brandData = [
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
];

const mapLocationURL =
  'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d193175.30893635444!2d-74.373409!3d40.841927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy%20Meadows%20Wetlands!5e0!3m2!1sen!2sbd!4v1701067943819!5m2!1sen!2sbd';

const Home = () => {
    const location = useLocation();
  const { hash, pathname, search } = location;

  const langData = {
    "path": pathname
  }

  return (
    <>
      <Hero data={heroData} />

      <About data={aboutData} />
      <Iconbox data={iconboxData} />
      <ContactInfo data={langData} />

      <footer className={`st-site-footer st-sticky-footer st-dynamic-bg`}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="st-vertical-middle">
                        <div className="st-vertical-middle-in py-3">
                            &copy; 2024 Aslı Kaptanoğlu
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </footer>
      {/* <Department />*/}
      {/* <Appointment />*/}
      {/* <SpecialistsSlider data={specialistData} />*/}
      {/* <hr />*/}
      {/* <MasonryGallery />*/}
      {/* <BeforeAfter data={beforeAfterData} />*/}
      {/* <TestimonialSlider data={testimonialData} />*/}
      {/* <Funfact />*/}
      {/* <PriceSlider data={priceData} />*/}
      {/* <Accordion data={faqData} />*/}
      {/* <Newsletter data={newsletterData} />*/}
      {/* <PostWrapper data={postData} />*/}
      {/* <BrandSlider data={brandData} />*/}
      {/* <Contact />*/}
    </>
  );
};

export default Home;
