import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '../Header/Header'
import Preloader from '../Preloader/Preloader'



const footerData = {
  "logo": "/images/footer-logo.png",
  "bgImg": "/images/footer-bg.png",
  "subTitle": " Lorem ipsum dolor sit consectet adipisicing sed do eiusmod temp incididunt ut labore. Lorem Ipsum is simply dummy.",
}
const Layout = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [isLoading, setIsLoading] = useState(true);

  const headerData = {
    "logo": "/images/logo.png",
    "path": pathname
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);


  return (
    <>
      {
        isLoading ? <Preloader /> : (
          <>
            <Header data={headerData}/>
            <Outlet />
            {/*<Footer data={footerData}/>*/}
          </>
        )
      }
    </>
  )
}

export default Layout;
