import React from 'react';
import HeroEn from '../Components/Hero/HeroEn';
import LocationInMap from '../Components/Map/LocationInMap';
import Contact from '../Components/Contact/Contact';
import Department from '../Components/Department/Department';
import Appointment from '../Components/Appointment/Appointment';
import AboutEn from '../Components/About/AboutEn';
import Iconbox from '../Components/Iconbox/Iconbox';
import PostWrapper from '../Components/Post/PostWrapper';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import BrandSlider from '../Components/Slider/BrandSlider';
import Newsletter from '../Components/Newsletter/Newsletter';
import Accordion from '../Components/Accordion/Accordion';
import PriceSlider from '../Components/Slider/PriceSlider';
import Funfact from '../Components/Funfact/Funfact';
import BeforeAfter from '../Components/BeforeAfter/BeforeAfter';
import MasonryGallery from '../Components/Gallery/Gallery';
import ContactInfo from '../Components/ContactInfo/ContactInfo';
import { useLocation } from 'react-router-dom';

const heroData = {
  bgImg: 'images/hero-bg.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },/*
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },*/
  ],
  title: ['Crutches', 'Laboratory', 'Cardiology', 'Dentist', 'Neurology'],
};

const iconboxData = [
  {
    fieldTitle: 'Our Treatments',
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Skin Cancer diagnosis and treatment',
    subTitle:
      'Dermatoscopic examination, biopsy and surgical excision are performed for the differential diagnosis of skin tumors. In addition, preventive medicine consultancy is applied by monitoring the disease, determining personal and family risks.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Nail diseases diagnosis teeatment and surgery',
    subTitle:
      'Differential diagnosis of nail diseases is very important, so a general dermatological examination is definitely performed. Dermoscopy, fungal and bacterial cultures, and biopsy for tumoral lesions may be performed. Chemical and surgical matrixectomy, nail bed revision, electrocauterization, cryotherapy can be performed for ingrown toenails, and surgical excision can be performed for nail tumors.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Chemical Peeling',
    subTitle:
      'Chemical Peeling treatment  is applied to treat skin spots, hyperpigmentation, melasma, wrinkles, acne and scars, skin changes caused by sun damage in addition to equalize skin tone.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Hyperhydrosis treatment',
    subTitle:
      'Sweating disorders can greatly impair the quality of life and may be associated with other diseases. In our clinic, patients are subjected to a complete examination in these respects and, if necessary, a sweat test is performed after their examinations. We have treatment options such as botulinum toxin application and iontophoresis.',
  },
  {
    bg: 'purple',
    icon: 'myicons/icon1.svg',
    title: 'Acne treatment',
    subTitle:
      'In our clinic, each acne patient is evaluated individually. Treatment plans are created taking into account the general health status, nutrition, medications used and the effect of acne on the quality of life. Skin cleansing and skin care, chemical peeling, prescription medications (topical or oral), surgical treatments for cystic lesions, microneedling and subcision for acne scars are applied.',
  },
];

const aboutData = {
  title:
    'About Me',
  subTitle:
    'Prof. Dr. Aslı Kaptanoglu graduated from Ankara Atatürk Anatolian High School in 1989. She completed her medical training in Ankara University Faculty of Medicine and  graduated in 1995. She completed her dermatology resident training at Ege University, Department of Skin and Venereal Diseases between 1996 and 2000 and became a specialist in 2000. With her scientific and academic activities, she received the title of Associate Professor in 2012 and Professor title in 2019. Between 2001 and 2010, she worked as a dermatology specialist at Private Bayındır Hospital in Ankara, focusing especially on dermatological surgery, laser treatments and cosmetic procedures. In 2010, she established the Department of Dermatology and Venereology of Near East University Faculty of Medicine, in North Cyprus. She served as the Chief of the Department between 2010 and 2015. In 2016, she was appointed to Istanbul Marmara University Faculty of Medicine, Department of Dermatology and Venereology, and took responsibility for the units of “Skin tumors and oncology”, “dermatological surgery” and “cosmetic dermatology”. Giving courses at undergraduate and graduate levels, Dr. Kaptanoğlu has many scientific articles published in reputable scientific journals, book chapters, experimental researches and editorships at home and abroad. Prof. Dr. Aslı F. Kaptanoğlu retired from Marmara University Faculty of Medicine, where she was working, in 2024. Prof. Dr Aslı Kaptanoglu is a member of Ankara Atatürk Anatolian High School Alumni Foundation, Turkish Dermatology Association, Dermatooncology Association, European Academy of Dermatology & Venereology and European Nail Society. In addition to her educative and consultancy services, Dr Aslı Kaptanoğlu has established her own private clinic and continues to serve her patients here.',

  avater: {
    img: 'images/avatar1.png',
    name: 'David Ambrose',
    designation: 'Founder & Director',
  },
  timeTable: [
    {
      day: 'Monday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Tuesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Wednesday',
      startTime: '9:00',
      endTime: '6:00',
    },
    {
      day: 'Thursday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Friday',
      startTime: '9:00',
      endTime: '5:00',
    },
    {
      day: 'Saturday',
      startTime: '8:00',
      endTime: '7:00',
    },
    {
      day: 'Sunday',
      startTime: '9:00',
      endTime: '3:00',
    },
  ],
  contact: '+90 (501) 000 42 66',
};

const specialistData = [
  {
    img: 'images/member1.jpg',
    name: 'Dr. Philip Bailey',
    designation: 'Urology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member2.jpg',
    name: 'Dr. Vera Hasson',
    designation: 'Cardiology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member3.jpg',
    name: 'Dr. Matthew Hill',
    designation: 'Neurosurgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member4.jpg',
    name: 'Dr. Jeanette Hoff',
    designation: 'Surgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member1.jpg',
    name: 'Dr. Philip Bailey',
    designation: 'Urology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member2.jpg',
    name: 'Dr. Vera Hasson',
    designation: 'Cardiology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member3.jpg',
    name: 'Dr. Matthew Hill',
    designation: 'Neurosurgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member4.jpg',
    name: 'Dr. Jeanette Hoff',
    designation: 'Surgery',
    authorLink: '/doctor-profile',
  },
];

const beforeAfterData = {
  bgImg: '/images/before-after-bg.jpg',
  beforeImg: '/images/after.jpg',
  afterImg: 'images/before.jpg',
};

const testimonialData = [
  {
    img: 'images/avatar2.png',
    name: 'Ralph Jones',
    designation: 'Executive',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar3.png',
    name: 'Francis Jara',
    designation: 'Biographer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar4.png',
    name: 'David Baer',
    designation: 'UX Designer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar2.png',
    name: 'Ralph Jones',
    designation: 'Executive',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar3.png',
    name: 'Francis Jara',
    designation: 'Biographer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: 'images/avatar4.png',
    name: 'David Baer',
    designation: 'UX Designer',
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
];

const priceData = [
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
];

const faqData = {
  title: 'Just Answer the Questions',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      title: 'What is Medi solution?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'How do I get a refill on my prescription?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'How competent our total treatment?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'If I get sick what should I do?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: 'What is emergency cary to your hospital?',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ],
};

const newsletterData = {
  bgImg: 'images/news-letter-bg.png',
  contact: '(+01) - 234 567 890',
};

const postData = [
  {
    img: 'images/blog1.jpg',
    title: 'Working in emergency medicine',
    date: 'Aug 23, 2020',
    author: 'Albert Brian',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog2.jpg',
    title: 'Individual treatment & assistance',
    date: 'Aug 22, 2020',
    author: 'William Juarez',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
];

const brandData = [
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
];

const mapLocationURL =
  'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d193175.30893635444!2d-74.373409!3d40.841927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy%20Meadows%20Wetlands!5e0!3m2!1sen!2sbd!4v1701067943819!5m2!1sen!2sbd';

const HomeEn = () => {
    const location = useLocation();
  const { hash, pathname, search } = location;

  const langData = {
    "path": pathname
  }

  return (
    <>
      <HeroEn data={heroData} />
      <AboutEn data={aboutData} />
      {<Iconbox data={iconboxData} />}
      <ContactInfo data={langData} />
      {/* <Department />*/}
      {/* <Appointment />*/}
      {/* <SpecialistsSlider data={specialistData} />*/}
      {/* <hr />*/}
      {/* <MasonryGallery />*/}
      {/* <BeforeAfter data={beforeAfterData} />*/}
      {/* <TestimonialSlider data={testimonialData} />*/}
      {/* <Funfact />*/}
      {/* <PriceSlider data={priceData} />*/}
      {/* <Accordion data={faqData} />*/}
      {/* <Newsletter data={newsletterData} />*/}
      {/* <PostWrapper data={postData} />*/}
      {/* <BrandSlider data={brandData} />*/}
      {/* <Contact />*/}
      {/* <LocationInMap data={mapLocationURL} />*/}
    </>
  );
};

export default HomeEn;
